require('./bootstrap');

$(function () {
    $('[data-toggle="tooltip"]').tooltip()


    setTimeout(() => {
        $('.notification').remove();
    }, 5000);

    $('.arrowScroll .arrow').on('click',function(){
        $(this).hide();
        if($(this).hasClass('right')){
            $('.table-responsive').animate({ scrollLeft: 1000 }, 600);
            $('.arrowScroll .arrow.left').show();
        }else{
            $('.table-responsive').animate({ scrollLeft: 0 }, 600);
            $('.arrowScroll .arrow.right').show();
        }
    })

    $('.launch-modal').on('click', function(e){
        e.preventDefault();
        $( '#' + $(this).data('modal-id') ).modal();
    });
    

    //FILTERS
    $('.sidebarProducts .option select, .sidebarProducts .option input, .filterGrid select').on('change', function () {
        var checkValue = $(this).val();
        var attr = $(this).attr('name');
        if($(this).attr('type') == 'checkbox' &&  !$(this).is(':checked')){
            checkValue = 0;
        }

        setGetParameter(attr, checkValue);
    })

    //SEARCH
    $('.searchInput').on('click', function(){
        $('.searchInputProduct').toggleClass('active');
        $('.searchInputProduct input').focus();
    })


    //MOBILE
    $('.sidebarProducts .filterClick').on('click', function(){
        $('.sidebarProducts form').toggle();
    })
    

    $('.sellerAction .showMore').on('click', function(){
        $(this).hide();
        $('.hiw-benefits').removeClass('d-none');
    })

    //BANNER
    $('#sliderBanner').carousel({
        pause: "false"
    });
})

function setGetParameter(paramName, paramValue){
    var url = decodeURI(window.location.href);
    var hash = location.hash;
    url = url.replace(hash, '');
    if (url.indexOf(paramName + "=") >= 0){
        var prefix = url.substring(0, url.indexOf(paramName + "=")); 
        var suffix = url.substring(url.indexOf(paramName + "="));
        suffix = suffix.substring(suffix.indexOf("=") + 1);
        suffix = (suffix.indexOf("&") >= 0) ? suffix.substring(suffix.indexOf("&")) : "";
        url = prefix + paramName + "=" + paramValue + suffix;
    }else{
        if (url.indexOf("?") < 0){
            url += "?" + paramName + "=" + paramValue;
        }else{
            url += "&" + paramName + "=" + paramValue;
        }
    }
    window.location.href = url + hash;
}

